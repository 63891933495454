import type { AxiosResponse } from "axios";

import {
  AccessLevel,
  CreateGroup,
  CreatePolicy,
  Group,
  Policy,
  UserProfile,
} from "../models/user.model";
import axiosClient from "./apiClient";
import { MdslApi } from "./mdsl.api";

const { get, post } = axiosClient;

class UserApi extends MdslApi {
  async getUserProfile(): Promise<UserProfile> {
    return get("/profile", await this.axiosDefaults).then((res) => res.data.data);
  }

  async getUserGroups(): Promise<Group[]> {
    return get("/groups", await this.axiosDefaults).then((res) => res.data.data.collection);
  }

  async getGroupById(groupId: string): Promise<Group> {
    return get(`/groups/${groupId}`, await this.axiosDefaults).then((res) => res.data.data);
  }

  async getPolicies(): Promise<Policy[]> {
    return get("/policies", await this.axiosDefaults).then((res) => res.data.data.collection);
  }

  async getPolicyById(policyId: string): Promise<Policy> {
    return get(`/policies/${policyId}`, await this.axiosDefaults).then((res) => res.data.data);
  }

  async addNewPolicyToGroup(groupId: string, options: CreatePolicy): Promise<Policy> {
    // /v4/groups/{groupUID}/policies
    return post(
      `/groups/${groupId}/policies`,
      {
        ...options,
      },
      {
        ...(await this.axiosDefaults),
      }
    ).then((res) => res.data.data);
  }

  async addNewGroup(options: CreateGroup): Promise<Group> {
    return post(
      "/groups",
      {
        ...options,
      },
      {
        ...(await this.axiosDefaults),
      }
    ).then((res) => res.data.data);
  }

  async addUserToGroup(
    groupId: string,
    userId: string,
    username: string,
    options: AccessLevel
  ): Promise<UserProfile> {
    // If we dont have a userId, we need to get it from the username
    if (!userId) {
      const user = await this.getUserByName(username);
      userId = user.uid;
    }
    // /v4/groups/{groupUID}/users/{userUID}
    return post(
      `/groups/${groupId}/users/${userId}`,
      {
        ...options,
      },
      {
        ...(await this.axiosDefaults),
      }
    ).then((res) => res.data.data);
  }

  async addNetworkToPolicyById(policyId: string | undefined, networkId: string): Promise<Policy> {
    if (!policyId) {
      throw new Error("Policy ID is required");
    }
    return post(`/policies/${policyId}/networks/${networkId}`, {}, await this.axiosDefaults).then(
      (res) => res.data.data
    );
  }

  async getUserByName(username: string): Promise<UserProfile> {
    // /v4/users/{userName}
    return get(`/users/${username}`, await this.axiosDefaults).then((res) => res.data.data);
  }
}

export default new UserApi();
